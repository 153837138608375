import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';
import parse from "html-react-parser";
import {getArticleUrl} from "../modules/utils";

const queryString = require("query-string")

/**
 * Article Preview Layout component
 * @returns {JSX.Element}
 * @private
 */
const ArticlePreviewLayout = (props) => {
    const {t} = useTranslation();
    const {languages, originalPath} = useI18next();

    const {
        title, // string
        date, // string
        dateISO, // string
        type, // ["news" | "event-highlights"]
        articleId, // string
    } = props

    // const articleQuery = {
    //     type,
    //     id: articleId
    // }
    // const articleUrl = `/media/article?${queryString.stringify(articleQuery)}`

    const articleUrl = getArticleUrl(type, articleId)

    useEffect(() => {

    }, [])

    return (
        <article className="entry">

            {/*<div className="entry-img">*/}
            {/*    <img src="../../content/assets/img/blog/blog-1.jpg" alt="" className="img-fluid"/>*/}
            {/*</div>*/}

            <h2 className="entry-title">
                <Link to={articleUrl}>{parse(title)}</Link>
            </h2>

            <div className="entry-meta">
                <ul>
                    {/*<li className="d-flex align-items-center"><i className="bi bi-person"></i> <a*/}
                    {/*    href="blog-single.html">John Doe</a></li>*/}
                    <li className="d-flex align-items-center">
                        <i className="bi bi-clock"></i>
                        <Link to={articleUrl}>
                            <time dateTime={dateISO}>{date}</time>
                        </Link>
                    </li>

                    {/*<li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a*/}
                    {/*    href="blog-single.html">12 Comments</a></li>*/}
                </ul>
            </div>

            <div className="entry-content">
                {/*<p>*/}
                {/*    Similique neque nam consequuntur ad non maxime aliquam quas. Quibusdam animi*/}
                {/*    praesentium. Aliquam et laboriosam eius aut nostrum quidem aliquid dicta.*/}
                {/*    Et eveniet enim. Qui velit est ea dolorem doloremque deleniti aperiam unde soluta.*/}
                {/*    Est cum et quod quos aut ut et sit sunt. Voluptate porro consequatur assumenda*/}
                {/*    perferendis dolore.*/}
                {/*</p>*/}
                <div className="read-more">
                    <Link to={articleUrl}>{t('readMore')}</Link>
                </div>
            </div>

        </article>
    );
};

export default ArticlePreviewLayout;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;